html,
body {
  height: 100%;
}
.app-error {
  background: no-repeat center / cover url('/api/v1/assets/login_background');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.app-error .app-error__image {
  color: #F44336;
  font-size: 256px;
  font-family: "Material Design Icons";
}
.app-error .app-error__image:before {
  content: '\F0027';
}
.app-error .app-error__info {
  background-color: white;
  border: 2px solid #EEEEEE;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
.app-error .app-error__info .app-error__title {
  margin-top: 32px;
  font-size: 32px;
  color: #2196F3;
}
.app-error .app-error__info .app-error__message {
  margin-top: 32px;
  font-size: 24px;
  color: #F44336;
}
