
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
html,body {
  height: 100%;
}
.app-error {
  background: no-repeat center/cover url('/api/v1/assets/login_background');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .app-error__image {
    color: #F44336;
    font-size: 256px;
    font-family: "Material Design Icons";
    &:before {
      content: '\F0027';
    }
  }
  .app-error__info {
    background-color: white;
    border: 2px solid #EEEEEE;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    .app-error__title {
      margin-top: 32px;
      font-size: 32px;
      color: #2196F3;
    }
    .app-error__message {
      margin-top: 32px;
      font-size: 24px;
      color: #F44336;
    }
  }
}
